import { CanActivateFn } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../services/api/auth.service';

export const AuthGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);

  const haveToken = authService.currentToken;

  if (!haveToken) {
    authService.logout();
    return false;
  }

  const validate$ = authService.validateToken();

  try {
    await lastValueFrom(validate$);
    return true;
  } catch (error) {
    authService.logout();
    return false;
  }
};
